@font-face {
  font-family: ogg;
  src: url(../../utils/Fonts/Ogg-Roman.otf) format("opentype");
}

@font-face {
  font-family: apercu-mono;
  src: url(../../utils/Fonts/ApercuMono.ttf) format("truetype");
}

.footer {
  width: 95vw;
  margin: 1rem auto;
  padding: 1rem;
}

.footer__row {
  background-image: repeating-linear-gradient(
      -74deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2) 1px,
      transparent 0,
      transparent 4px
    ),
    repeating-linear-gradient(
      -74deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2) 1px,
      transparent 0,
      transparent 4px
    );
  padding: 7px;
  width: 90vw;
  margin: auto;
}

.interested {
  font-family: ogg, sans-serif;
  font-size: 45px;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 100;
  padding: 30px;
}

.interested p {
  line-height: 1em;
}

.interested a {
  font-family: apercu-mono, sans-serif;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  font-size: 16px;
  padding: 15px;
  border: 1px solid #000;
  width: 100%;
  max-width: 180px;
  display: inline-block;
  margin: 14px auto;
  transition: background 0.6s cubic-bezier(0.23, 1, 0.32, 1),
    color 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.interested a:hover {
  background: #000;
  color: #fff;
  cursor: pointer;
}

.social {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5rem;
  width: 90vw;
  margin: 5rem auto;
  font-family: apercu-mono, sans-serif;
}

.socialBox {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.socialBoxItem {
  padding: 2rem;
  background-image: linear-gradient(90deg, #000 50%, transparent 0),
    linear-gradient(90deg, #000 50%, transparent 0),
    linear-gradient(180deg, #000 50%, transparent 0),
    linear-gradient(180deg, #000 50%, transparent 0);
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 30px 1px, 30px 1px, 1px 30px, 1px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: 0.4s infinite normal a;
  animation-timing-function: linear;
  animation-play-state: paused;
}

.socialBoxItem:hover {
  animation-play-state: running;
}

@keyframes a {
  0% {
    background-position: 0 0, 0 100%, 0 0, 100% 0;
  }

  100% {
    background-position: 30px 0, -30px 100%, 0 -30px, 100% 30px;
  }
}

.inspiration {
  font-family: apercu-mono, sans-serif;
  color: black;
  margin: 1rem;
}
