@font-face {
  font-family: ogg;
  src: url(../../utils/Fonts/Ogg-Roman.otf) format("opentype");
}

@font-face {
  font-family: apercu-mono;
  src: url(../../utils/Fonts/ApercuMono.ttf) format("truetype");
}

.main {
  width: 95vw;
  margin: 1rem auto;
  padding: 1rem;
  text-align: left;
}

.mainHeading {
  margin: 1rem auto;
  padding: 1rem;
}

/* THINGS I MADE */
.mainHeading h3 {
  font-family: ogg, sans-serif;
  font-size: 4rem;
  width: 100%;
  font-weight: 100;
  text-align: center;
}

.mainHeading > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem 4rem;
  width: 90vw;
  margin: 60px auto;
}

.projectCard {
  padding: 4rem;
  background-image: linear-gradient(90deg, #000 50%, transparent 0),
    linear-gradient(90deg, #000 50%, transparent 0),
    linear-gradient(180deg, #000 50%, transparent 0),
    linear-gradient(180deg, #000 50%, transparent 0);
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 30px 1px, 30px 1px, 1px 30px, 1px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: 0.4s infinite normal a;
  animation-timing-function: linear;
  animation-play-state: paused;
  position: relative;
}

.projectCard:hover {
  animation-play-state: running;
}

@keyframes a {
  0% {
    background-position: 0 0, 0 100%, 0 0, 100% 0;
  }

  100% {
    background-position: 30px 0, -30px 100%, 0 -30px, 100% 30px;
  }
}

/* THINGS I KNOW */
.tech {
  padding: 2rem;
}
.tech h3 {
  font-family: ogg, sans-serif;
  font-size: 4rem;
  text-align: center;
  font-weight: 100;
  padding-bottom: 3rem;
}

.headingHr {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 4rem;
  justify-items: center;
}

.headingHr > img {
  height: 8em;
}

c {
  animation-play-state: running;
}

@keyframes c {
  0% {
    background-position-x: 0, 0;
  }
  100% {
    background-position-x: 24px, -24px;
  }
}
